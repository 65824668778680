<script setup>
import { defineProps, ref, defineEmits, onMounted, onUnmounted } from "vue";
import Cropper from "cropperjs";
import { saveAs } from "file-saver";

const props = defineProps({
  token: Object,
});

const emit = defineEmits(["cancel"]);

const imagecontainer = ref(null);
const cropper = ref(null);
onMounted(() => {
  cropper.value = new Cropper(imagecontainer.value, {
    aspectRatio: 1 / 1,
  });
});
onUnmounted(() => {
  cropper.value?.destroy();
});

const crop = () => {
  let url = cropper.value.getCroppedCanvas().toDataURL("image/jpeg");
  saveAs(url, `Society of the Hourglass #${props.token.index}.jpg`);
};

const cancel = () => {
  emit("cancel");
};
</script>

<template>
  <div class="w-full">
    <div class="w-full relative">
      <div class="absolute right-2 top-2 rounded bg-white w-8 h-8 cursor-pointer z-40 shadow flex items-center justify-center" @click="crop">
        <img :src="require('@/assets/icons/download.svg')" style="height: 20px" />
      </div>
      <div class="absolute left-2 top-2 rounded bg-ch1red w-8 h-8 cursor-pointer z-40 shadow flex items-center justify-center" @click="cancel">
        <img :src="require('@/assets/icons/close-white.svg')" style="height: 15px" />
      </div>
      <img ref="imagecontainer" :src="props.token.image" />
    </div>
  </div>
</template>
